import React from "react"
import { Link, graphql } from "gatsby"
import SEO from "../components/seo"
import Img from "gatsby-image"
import "../styles/404.css"

class NotFoundPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      temp: null,
      count: 0
    }
  }
  render() {
    const {
      data
    } = this.props

    return ( 
      <>
        <SEO title="404: Not found" />
        <section id="e404" class="e404">
        
            <h1>404</h1>
            <div className="e404--inner">
              <h2 className="heading">Det har vi ikke noget svar på!</h2>
              <p>Det ser ud som om du farede vild et sted i vores pit lane. Måske du ledte efter et kommende eller tidligere <Link to="/races" className="inline-click">løb</Link> eller ville læse en <Link to="/blog" className="inline-click">artikel</Link>.</p>
              <p>Du kan også starte fra <Link to="/" className="inline-click">begyndelsen</Link>.</p>
            </div>

            {data.allDirectusPageSetting.edges.map(edge => (
              <Img fluid={edge.node._404_page_image.localFile.childImageSharp.fluid} className="e404--background" />
            ))}
        
        </section>
      </>
    )}

}

export default NotFoundPage

export const query = graphql`
  query {
      allDirectusPageSetting {
        edges {
          node {
            _404_page_image {
              localFile {
                childImageSharp {
                  fluid(quality: 75) {
                      ...GatsbyImageSharpFluid_withWebp            
                  }
                }
              }
            }
          }
        }
      }
  }
`